import { addDoc, collection } from "firebase/firestore";
import firebase from '../firestore';

var db = firebase.firestore();

function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }
function getDate(dateTime) {
  var fullDate = {}
  console.log(dateTime)
  if(dateTime){
    fullDate.year = dateTime.substring(0, getPosition(dateTime, '/', 1))
    fullDate.month = dateTime.substring(getPosition(dateTime, '/', 1)+1, getPosition(dateTime, '/', 2))
    fullDate.day = dateTime.substring(getPosition(dateTime, '/', 2)+1, getPosition(dateTime, ' ', 1))
    fullDate.hour = dateTime.substring(getPosition(dateTime, ' ', 1)+1, getPosition(dateTime, ':', 1))
    fullDate.minute = dateTime.substring(getPosition(dateTime, ':', 1)+1)
  }
  return fullDate;
}
// add zeros to make a number into string ex: 3 digits 1 becomes 001
function addZero (number, digitNumber){
  var stringNumber = number.toString()
  while (stringNumber.length < digitNumber)
    stringNumber = '0' + stringNumber
  
  return stringNumber
}

//pu date in more human readable format
const formatDate = (date) => {
  if(date){
    var formattedDate = ''
    if (date.day !== 'undefined')
        formattedDate = date.day + '/' + date.month + '/' + date.year + ' ' + date.hour + ':' + date.minute
    return formattedDate
  }
}

function dateToString(date) {
  
  var stringDate = ''
  if(date !== null && date !== undefined){
    // transform mont and day into string
    var month = date.month.toString()
    var day = date.day.toString()

    // add up to digits for month and day
    month = addZero(month, 2)
    day = addZero(day, 2)

    //check if the date contains hours
    try{
      if(date.hour === undefined) {
        date.hour = '00'
        date.minute = '00'
        // // add 0 to date
        
      }
    }catch(e){
    }
    if(date !== "undefined" && date !== null){
      

      stringDate = date.year + '/' + month + '/' + day + ' ' + date.hour + ':' + date.minute
    }
  }
  
  return stringDate;
}

function dateToSmallString(date) {
  
  var stringDate = ''
  if(date !== null && date !== undefined){
    // transform mont and day into string
    var month = date.month.toString()
    var day = date.day.toString()

    // add up to digits for month and day
    month = addZero(month, 2)
    day = addZero(day, 2)

    //check if the date contains hours
    try{
      if(date.hour === undefined) {
        date.hour = '00'
        date.minute = '00'
        // // add 0 to date
        
      }
    }catch(e){
    }
    if(date !== "undefined" && date !== null){
      

      stringDate = day + '/' + month + '/' + date.year 
    }
  }
  
  return stringDate;
}



function setDefaultValueIfNull(doc){
  if(doc === null)
    doc.value =doc.defaultValue
  return doc
}
function getSqipRate(){
  return 0.017
}
function getPSPRate(){
  return 0.013
}
async function sendSMS(recepient, message){
  // Must no send null recepient 
  if (recepient === null)
    recepient = '+41'

  await addDoc(collection(db, "smsInfos"), {
    recepient: recepient,
    message: message
  });

  
}

// Returns a csv from an array of objects with
// values separated by tabs and rows separated by newlines
function arrayToCSV(array) {
  // Use first element to choose the keys and the order
  var keys = Object.keys(array[0]);

  // Build header
  var result = keys.join("\t") + "\n";

  // Add the rows
  array.forEach(function(obj){
      result += keys.map(k => obj[k]).join("\t") + "\n";
  });

  return result;
}
// check if file exists
function fileExists(url){

  var http = new XMLHttpRequest();

  http.open('HEAD', url, false);
  http.send();

  return http.status !== 404;

}

function translate(word){
  if(word){
    if(word[localStorage.getItem('language')])
      return word[localStorage.getItem('language')]
    else 
    {
      return word['default']
    }
  }
  else return null

}

function isNull(v){
  if(v===null)
    return true
  else return false
}
function isPositiveInteger(str) {
  const num = Number(str);

  if (Number.isInteger(num) && num >= 0) {
    return true;
  }

  return false;
}
// get list of years in a given range
function getYears(startYear, endYear) {
  let years = [];
  for (let i = startYear; i <= endYear; i++) {
    let date = new Date(i, 0, 1);
    years.push(date.getFullYear());
  }
  return years;
}
// get list of payout periods bases on method and number of payment
function getListOfPeriods(method, parts, year, timestamp) {
  var startDate
  var endDate
  if(timestamp){
    if(timestamp.start)
      startDate = timestamp.start
    if(timestamp.end)
      endDate = timestamp.end
  }
  switch (method) {
    case "monthly":
      let dates = [];
      for (let i = 0; i < 12; i++) {
        let date = new Date(year, i, 1);
        const monthLength = new Date(year, i + 1, 0).getDate();
        const interval = Math.floor(monthLength / parts);
        for (let j = 0; j < parts; j++) {
            let start = new Date(date.getFullYear(), date.getMonth(), j * interval +1);
            start.setHours(0, 0, 0, 0);
            let end = new Date(date.getFullYear(), date.getMonth(), (j+1)  * interval);
            if(j === parts-1)
              end = new Date(date.getFullYear(), date.getMonth(), monthLength);

            end.setHours(23, 59, 59, 999);
            if((((start.toISOString() >= startDate)|| !startDate) && (end.toISOString() <= endDate || !endDate)) || !timestamp)
              dates.push({value:{ start: start, end:end }, label: start.toLocaleDateString() +'-'+ end.toLocaleDateString()});
        }
      }
      return dates;
      
  
    default:
      return timestamp

  }
  
}
// check if date is in format dd.mm.yyyy
function isValidDate(dateString) {
  var regex_date = /^\d{2}\.\d{2}\.\d{4}$/;
  if(!regex_date.test(dateString)) {
    return false;
  }
  var parts = dateString.split(".");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);
  if(year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false;
  }
  
  var monthLength = [31,28,31,30,31,30,31,31,30,31,30,31];
  if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }
  return day > 0 && day <= monthLength[month -1];
}

// function to clear oreder storage
function clearOrderLocalStorage() {
  localStorage.setItem('order', null);
  localStorage.setItem('totalDeposit', null);
  localStorage.setItem('phoneNumber', null);
  localStorage.setItem('sqipRate', null);
  localStorage.setItem('sectionName', null);
  localStorage.setItem('officialName', null);
  localStorage.setItem('adress', null);
  localStorage.setItem('locality', null);
  localStorage.setItem('vatNumber', null);
  localStorage.setItem('SMSMessage', null);
  localStorage.setItem('userInfos', null);
  localStorage.setItem('comment', null);
  localStorage.setItem('bringToCompany', null);
  localStorage.setItem('noPreparation', null);
}

export {
  getPosition, getDate, setDefaultValueIfNull,
  getSqipRate, getPSPRate, sendSMS, fileExists, arrayToCSV,
  translate, dateToString, dateToSmallString, addZero, isNull, formatDate, 
  isPositiveInteger, getYears, getListOfPeriods, isValidDate, clearOrderLocalStorage
};