import firebase from '../firestore';
import { collection, addDoc, updateDoc, doc, setDoc, getDocs, getDoc, deleteDoc, query, where} from "firebase/firestore"; 
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { authDomain, databaseURL } from '../tools/utils';
import { getPosition} from '../tools/Tools';
import { traductionMap } from '../componants/Traduction';

var db = firebase.firestore();
const eventsPath = 'events/'

// ========================================== any doc Repository =================================================
//get any doc by id
async function getAnyDocById(path, id){
    var item = {};
    console.log(path)
    console.log(id)
    console.log(db)
    const docRef = doc(db, path, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        item = docSnap.data()
        item.id = docSnap.id
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    return item
}
//delete any doc by id
async function DeleteAnyDocById(path, id){
    console.log(path, id)
    await deleteDoc(doc(db, path, id));
}
//get all docs from a collection
async function getAllDocs(path){
    var itemList = [];
    await getDocs(collection(db, path)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.exists){
              // Convert to item object
            var item;
            item = doc.data();
            item.id = doc.id;
            itemList.push(item);
            } else {
                console.log("No such document!")
        }})
    })
    return itemList
}
 // ========================================== Event Repository =================================================
//Add event in firebase
async function AddFirebaseEvent (event, path)   {
    await addDoc(collection(db, path), {
        eventName: event.eventName,
        open: false
    }).then(()=>{
        alert('Événement ajouté')
    }).catch((error)=>{
        alert(error)
    })
}
//update event in firebase
async function UpdateFirebaseEvent (event, path)   {
    console.log(event)
    console.log(path)
    const docRef = doc(db, path, event.id);
    await updateDoc(docRef, {
        eventName: event.eventName,
        invisible: event.invisible
    }).then(()=>{
        alert('Événement mis à jour')
    }).catch((error)=>{
        console.log(error)
        alert(error)
    });
}
async function DeleteFirebaseEvent(event, path, items){
    if(window.confirm('Êtes vous sûr de vouloir supprimmer cet événement ?'))
    {
        db.collection(path).doc(event.id).delete()
        items.splice(items.indexOf(event), 1)
        alert('Événement supprimmé')
    }
}
//get all events
async function getAllEvents(){
    var itemList = [];
    await getDocs(collection(db, eventsPath)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.exists){
              // Convert to item object
            var item;
            item = doc.data();
            item.id = doc.id;
            itemList.push(item);
            } else {
                console.log("No such document!")
        }})
    })
    return itemList
}
//update event open
async function UpdateFirebaseEventOpen (event, path)   {
    const docRef = doc(db, path, event.id);
    await updateDoc(docRef, {
        open: !event.open
    })
}
//get order by event
async function GetOrdersByEvent(path) {
    var orders = [];
    const querySnapshot = await getDocs(collection(db, path));
    for (const eventDoc of querySnapshot.docs) {
      const boothSnapshot = await getDocs(
        collection(db, path + eventDoc.id + "/commands")
      );
      for (const doc of boothSnapshot.docs) {
        var document = doc.data();
        document.id = doc.id;
        document.boothName = eventDoc.data().menuName;
        orders.push(document);
      }
    }
    return orders;
  }
// ===============================================================================================================
 // ========================================== Booth Repository =================================================
//Add Booth in firebase
async function AddFirebaseBooth (booth, path)   {
    const docRef = await addDoc(collection(db, path), {
        menuName: booth.boothName,
        officialName: booth.officialName,
        adress: booth.adress,
        locality: booth.locality,
        vatNumber: booth.vatNumber,
        SMSMessage: booth.SMSMessage,
        postponedPayment: booth.postponedPayment,
        pmList: ['twint'],
        sqipRate: 0.017,
        open: false,
        color: '#ffffff',
        testMode: false
    })
    console.log(docRef.id)
    //Add the lase command number
    setDoc(doc(db, path + docRef.id + '/commands/', "command"), {
        lastCommand: 0
    }).then(()=>{
        alert('Stand ajouté')
    });

}
//update Booth in firebase
async function UpdateFirebaseBooth(booth, path)   {
    console.log(booth)
    const docRef = doc(db, path, booth.id);
    await updateDoc(docRef, {
        menuName: booth.menuName,
        officialName: booth.officialName,
        adress: booth.adress,
        locality: booth.locality,
        vatNumber: booth.vatNumber,
        SMSMessage: booth.SMSMessage,
        postponedPayment: booth.postponedPayment,
        noPreparation: booth.noPreparation,
        color: booth.color,
        testMode: booth.testMode
    }).then(()=>{
        alert('Stand mis à jour')
    }).catch((error)=>{
        alert(error)
    })
}
async function UpdateTotalBoothDeposit(boothId, totalDeposit, path)   {
    const docRef = doc(db, path, boothId);
    await updateDoc(docRef, {
        totalBoothDeposit: totalDeposit
    }).then(()=>{
    }).catch((error)=>{
        alert(error)
    })
}

async function DeleteFirebaseBooth(booth, path, items){
    if(window.confirm('Êtes vous sûr de vouloir supprimmer ce stand ?'))
    {
        db.collection(path).doc(booth.id).delete();
        items.splice(items.indexOf(booth), 1)
        alert('Stand supprimmé')
        
    }
}

async function getBoothsByEvent(eventId){
    var itemList = [];
    await getDocs(collection(db, eventsPath + eventId + '/menus/')).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          if (doc.exists){
              // Convert to item object
            var item;
            item = doc.data();
            item.id = doc.id;
            itemList.push(item);
            } else {
                console.log("No such document!")
        }})
    })
    return itemList
}
//update booth open
async function UpdateFirebaseBoothOpen (booth, path)   {
    const docRef = doc(db, path, booth.id);
    await updateDoc(docRef, {
        open: !booth.open
    });
}
async function GetBoothById(path, boothId){
    const docRef = doc(db, path, boothId);
    const docSnap = await getDoc(docRef);
    var booth = {}
    if (docSnap.exists()) {
        booth = docSnap.data()
        booth.id = docSnap.id
    } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    }
    return booth
}

// Get booth VAT list
async function GetVatList(path){
    const colSnap = await getDocs(collection(db, path));
    var vatList = []
    colSnap.forEach((doc) => {
        vatList.push(doc.data().tva)
    });
    var uniq = [ ...new Set(vatList) ];
    return uniq
}
// get the name of booth by full path
async function GetBoothNameByFullPath(path){
    
    const boothPath = path.substring(0, getPosition(path, '/', 3))
    const boothId = path.substring(getPosition(path, '/', 3)+1).slice(0,-1)
    const docRef = doc(db, boothPath, boothId);
    const docSnap = await getDoc(docRef);
    var booth = {}
    if (docSnap.exists()) {
        booth = docSnap.data()
        booth.id = docSnap.id
    } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    }
    
    return booth.menuName
}
//get order by booth
async function GetOrdersByBooth(path) {
    var orders = [];
    const querySnapshot = await getDocs(collection(db, path));

    for (const doc of querySnapshot.docs) {
        var document = doc.data();
        document.id = doc.id;
        orders.push(document);
    }
    
    return orders;
  }

//
// ===============================================================================================================
// ============================================ user repository ==================================================

async function addUser (email, password, role, path, paths){
    const user = await getRolebyId(getAuth().currentUser.uid)
    //set secondary app for new user
    var config = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: authDomain,
        databaseURL: databaseURL};
    var secondaryApp = firebase.initializeApp(config, "Secondary");
    // Check privilege of the user
    // add the authentification of the user
    if(user.role === 'SuperAdmin' || 
        (user.role === 'EventAdmin' && user.path === path.substring(0, path.indexOf('/menus'))) ||
        (user.role === 'BoothAdmin' && user.path === path && (role === 'boothAdmin' || role === 'staff')))
    {
        secondaryApp.auth().createUserWithEmailAndPassword(email, password)
        .then((userCredentials) => {
            //add user in db
            setDoc(doc(db, "users/", userCredentials.user.uid), {
                role: role,
                path: path,
                paths: paths
            }).then(()=>{
                alert('Création de compte effectuée')
            })
            .catch((error) => {
                alert('Echec de la création: '+ error)
            })
        })
        .catch((error) => {
        alert('Echec de la création: '+ error)
        });

    }
    else{
        alert('Votre rôle ne permer pas la création de ce type d\'utilisateur \n ' + 
        'Vôtre rôle :' + user.role + 
        '\n Le rôle que vous tenetez de créer : ' + role)
    }
    
}
function resetPassword (email){
    if(window.confirm('Etes vous sûr de vouloir réinitialiser le mot de passe de : ' + email + ' ?')){sendPasswordResetEmail(getAuth(), email)
        .then(() => {
            alert('email de rénitialisation envoyé')
        })
        .catch((error) => {
            console.log(error)
            alert(error.message)
        });
    }
    
}
async function getRolebyId(uid){
    var item = {};
    const docRef = doc(db, 'users', uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        item = docSnap.data()
        item.id = docSnap.id
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    return item
    
    
}

// ===============================================================================================================
 // ========================================== Type Repository =================================================
//Add type in firebase
async function AddFirebaseType (type, path)   {
    await addDoc(collection(db, path), {
        type: type.typeName,
        number: type.number
    }).then(()=>{
    }).catch((error)=>{
        alert(error)
    });
}
//update type in firebase
async function UpdateFirebaseType(type, path)   {
    
    console.log(type)
    const docRef = doc(db, path, type.id);
    await updateDoc(docRef, {
        type: type.type,
        invisible: type.invisible
    }).then(()=>{
    }).catch((error)=>{
        alert(error)
    })
}
//get type by booth
async function getFirebaseTypebyBooth(path)   {
    const colSnap = await getDocs(collection(db, path));
    var types = []
    colSnap.forEach((doc) => {
        types.push(doc.data())
    });
    return types
}
// ===============================================================================================================
// ========================================== Order Repository =================================================
async function UpdateDepositAmount(orderId, depositAmount, path, refund){
    console.log(orderId+ path+depositAmount)
    const docRef = doc(db, path, orderId);
    await updateDoc(docRef, {
        refund: refund,
        givenBackMoney: depositAmount
    }).then(()=>{
    }).catch((error)=>{
        alert(error)
    })
}
// ===============================================================================================================
// ========================================== Company Repository =================================================
async function GetCompanyList(){
    const colSnap = await getDocs(collection(db, 'companies'));
    var companyList = []
    colSnap.forEach((doc) => {
        companyList.push({value: doc.data().name, label: doc.data().name })
    })
    return companyList
}
// ===============================================================================================================
// ========================================== items Repository =================================================
async function GetItemsByBooth(path){
    const colSnap = await getDocs(collection(db, path + 'items'));
    var items = []
    colSnap.forEach((doc) => {
        var item = doc.data()
        item.id = doc.id
        items.push(item)
        items = items.sort((a, b) =>{
            if (isNaN(a.itemNumber)) 
              a.itemNumber = 0;
            if (isNaN(b.itemNumber))
              b.itemNumber = 0
    
            return a.itemNumber - b.itemNumber
          } )
    })
    return items
}
async function GetItemsByType(path, type){
    const colSnap = await getDocs(collection(db, path + 'items'));
    var items = []
    colSnap.forEach((doc) => {
        var item = doc.data()
        item.id = doc.id
        if(item.type['default'] === type)
            items.push(item)
        items = items.sort((a, b) =>{
            if (isNaN(a.itemNumber)) 
              a.itemNumber = 0;
            if (isNaN(b.itemNumber))
              b.itemNumber = 0
    
            return a.itemNumber - b.itemNumber
          } )
    })
    return items
}
// ===============================================================================================================
// ========================================== Add for hardware listeners =================================================
async function  openCashDrawer () {
    await addDoc(collection(db, 'cashDrawerTrigger'), {
        message: 'echo hello',
        key: localStorage.getItem('cashDrawerKey')
    }).then(()=>{
    }).catch((error)=>{
        alert(error)
    });
    
}
// ===============================================================================================================
// ========================================== order repository =================================================
async function  changeOrderPaymentMean (path, id, value) {
    const docRef = doc(db, path, id);
    await updateDoc(docRef, {
        paymentMean: value
    }).then(()=>{
    }).catch((error)=>{
        alert(error)
    })
    
}
// ===============================================================================================================
// ========================================== form repository =================================================
async function GetFormList(path){
    const colSnap = await getDocs(collection(db, path + 'forms'));
    var forms = []
    colSnap.forEach((doc) => {
        var form = {}
        form.value = doc.id
        form.label = doc.data().name
        forms.push(form)
        forms = forms.sort()
    })
    return forms
}
async function GetFormById(path, id){
    console.log(path)
    console.log(id)
    const docRef = doc(db, path + 'forms', id);
    console.log(docRef)
    const docSnap = await getDoc(docRef);
    var form = {}
    if (docSnap.exists()) {
        form = docSnap.data()
        form.id = docSnap.id
        console.log(form)
    } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    }
    return form
}
async function setFormById(path, id, formData){
    console.log(path + 'forms')
    //Add the lase command number
    setDoc(doc(db, path + 'forms' , id), 
    {formContent: formData.formContent, name: formData.name, CGULink: formData.CGULink, open: formData.open}, {merge:true}).then(()=>{
    }).then(()=>{
        alert(traductionMap.changesSaved)
    });
}

async function setFilledFormById(path, id, formData){
    console.log(id)
    const docRef = doc(db, path + 'filledForms', id);
    //Add the lase command number
    console.log(formData.name + formData.basicFormId + formData.formContent)
    setDoc(doc(db, path + 'filledForms' , docRef.id), 
    {
        formContent: formData.formContent, 
        name: formData.name, 
        basicFormId: formData.basicFormId,
        paymentStatus: false
    }, {merge:true}).then(()=>{
    })
}
async function setFilledFormPaymentStatus(path, id){
    console.log(path)
    console.log(id)
    const docRef = doc(db, path + 'filledForms', id);
    //Add the lase command number
    try{
        await updateDoc(docRef, {
            paymentStatus: true
        }).then(()=>{
        }).catch((error)=>{
            alert(error)
        })
       
    }catch(e){
        console.log(e)
    }
}

async function GetFilledFormList(path, id){
    const colSnap = await getDocs(
        query(collection(db, path + 'filledForms'), where('basicFormId', '==', id))
      );
    var forms = []
    colSnap.forEach((doc) => {
        var form = {}
        form = doc.data()
        form.id = doc.id
        forms.push(form)
        forms = forms.sort()
    })
    return forms
}
export {AddFirebaseEvent, UpdateFirebaseEvent, DeleteFirebaseEvent, getAllEvents, GetOrdersByEvent, getAnyDocById, DeleteAnyDocById, UpdateFirebaseEventOpen,
        AddFirebaseBooth, UpdateFirebaseBooth, DeleteFirebaseBooth, getBoothsByEvent, UpdateFirebaseBoothOpen, GetBoothById,
        addUser, resetPassword, AddFirebaseType, UpdateFirebaseType, UpdateDepositAmount, UpdateTotalBoothDeposit, GetVatList,
        getFirebaseTypebyBooth, GetBoothNameByFullPath, GetCompanyList, GetItemsByBooth, openCashDrawer, GetOrdersByBooth, 
        changeOrderPaymentMean, GetFormList, GetFormById, setFormById, GetItemsByType, setFilledFormById, GetFilledFormList,
        getAllDocs, setFilledFormPaymentStatus };