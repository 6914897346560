import  firebase from '../firestore'
import { collection, query, where, onSnapshot } from "firebase/firestore";
import {useForceUpdate} from '../tools/utils'
import iconSqip from '../ressources/iconSqip.png'
import closeButton from '../ressources/closeCross.png'
import { getPosition } from '../tools/Tools';
import NotifSound from '../ressources/notifSound.wav'

var db = firebase.firestore();
function Notification(){
    var notifSound = new Audio(NotifSound);
    notifSound.muted = true;
    var notifs = []
    const forceUpdate = useForceUpdate()

    if(localStorage.getItem('notifs')){
        notifs = JSON.parse(localStorage.getItem('notifs'))
    }
    // listen only to orders with the cutomer id
    const q = query(collection(db, "Notifications"), where("customerId", '==', localStorage.getItem('customerId')))
          
    onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
            if (change.type === "modified") {
                if(change.doc.data().commandStatus === 2){
                    notifs.push({id: change.doc.id, visible: true, number: change.doc.data().number, message: change.doc.data().message})
                    localStorage.setItem('notifs', JSON.stringify(notifs))
                    notifSound.muted = false;
                    notifSound.play()
                    window.navigator.vibrate([2000,30,2000,30,500])
                    forceUpdate()
                }
            }
        });
    });
            
    const hideNotif = (notif) => {
        notif.visible = false
        localStorage.setItem('notifs', JSON.stringify(notifs))
        forceUpdate()
    }

    const goToOrders = (notif) => {
        hideNotif(notif)
        var myOrdersPath = window.location.href.substring(0, getPosition(window.location.href, '/', 3)) + '/myOrders'
        window.location.replace(myOrdersPath)
    }
    
    return(
        <div className='notificationContainer'> 
        {notifs.map((notif) =>
            notif.visible === true?
            <div className='notification' >
                <div style={{backgrounColor: 'black'}} onClick={()=> goToOrders(notif) }>
                    <img src={iconSqip} className="App-logo" alt="icon" /> 
                    <div style={{color: '#ec7c34'}}> sQip </div>
                </div>
                <div onClick={()=> goToOrders(notif)}>{notif.message}</div>
                <img className= 'closeButton' src={closeButton}  alt="closeButton" onClick={()=> hideNotif(notif) }/> 
                
            </div>:null
            )
        }
        </div>
    )
}
export default Notification