import React, { useState } from 'react';
import { Translate, HomeRounded, LocalGroceryStoreRounded, ReceiptLong, HelpRounded, ContactPageRounded, AccountCircleRounded, QrCodeScanner } from '@mui/icons-material';
import { availableLanguages, getCountryList, setLanguage, traductionMap } from '../componants/Traduction';
import { auth } from '../firestore';

const NavConsumer = () => {
    const [showNav, setShowNav] = useState(false);
    const [hamburgerColorChange, setHamburgerColorchange] = useState(false);
    const [navbarColorChange, setNavbarColorChange] = useState(false);
    const [visibility, setVisibility] = useState('hidden');

    const handleShowNav = () => setShowNav(!showNav);
    const closeNav = () => setShowNav(false);

    const handleHamburgerColorChange = () => setHamburgerColorchange(window.scrollY >= 80);
    const handleNavbarColorChange = () => setNavbarColorChange(window.scrollY >= 80);

    window.addEventListener('scroll', handleHamburgerColorChange);
    window.addEventListener('scroll', handleNavbarColorChange);

    const changeLanguage = (l) => {
        const languageKey = Object.keys(availableLanguages).find(key => availableLanguages[key] === l);
        localStorage.setItem('language', languageKey);
        setLanguage(languageKey);
        setVisibility('hidden');
        window.location.reload();
    };

    const redirectTo = (path) => {
        window.location.href = `https://sqipprod.web.app${path}`;
    };

    return (
        <div>
            <div className='selectorContainer' style={{ visibility: visibility }}>
                <div style={{ overflow: 'auto', maxHeight: '65%' }}>
                    {getCountryList().map(name => 
                        <div className='selector' onClick={() => changeLanguage(name)}>
                            {name}
                        </div>
                    )}
                </div>
            </div>
            <nav className={navbarColorChange ? `consumerNavbar navbarColorChange ${showNav ? "showSideBar" : "hideSideBar"}` : `consumerNavbar ${showNav ? "showSideBar" : "hideSideBar"}`}>
                <div className="navContainer">
                    <button className={hamburgerColorChange ? 'navbarBurger colorChange' : 'navbarBurger'} onClick={handleShowNav}>
                        <span className="burgerBar"></span>
                    </button>
                    <ul>
                        <div className="navLeft">
                            <li>
                                <div className='navLink' onClick={() => { redirectTo('/'); closeNav(); }}>
                                    <i><HomeRounded /></i>
                                    <span>{traductionMap.home}</span>
                                </div>
                            </li>
                            <li>
                                <div className='navLink' onClick={() => { redirectTo('/Events'); closeNav(); }}>
                                    <i><LocalGroceryStoreRounded /></i>
                                    <span>{traductionMap.order}</span>
                                </div>
                            </li>
                            <li>
                                <div className='navLink' onClick={() => { redirectTo('/ScanPaymentQR'); closeNav(); }}>
                                    <i><QrCodeScanner /></i>
                                    <span>{traductionMap.pay}</span>
                                </div>
                            </li>
                            <li>
                                <div className='navLink' onClick={() => { redirectTo('/MyOrders'); closeNav(); }}>
                                    <i><ReceiptLong /></i>
                                    <span>{traductionMap.myOrders}</span>
                                </div>
                            </li>
                            <li className='navSeparator'>
                                <div></div>
                            </li>
                            <li>
                                <div className='navLink' onClick={() => setVisibility('visible')}>
                                    <i><Translate /></i>
                                    <span>{availableLanguages[localStorage.getItem('language') ? localStorage.getItem('language') : navigator.language.substring(0, 2)]}</span>
                                </div>
                            </li>
                            <li>
                                <div className='navLink' onClick={() => { redirectTo('/UserGuide'); closeNav(); }}>
                                    <i><HelpRounded /></i>
                                    <span>{traductionMap.help}</span>
                                </div>
                            </li>
                            <li>
                                <div className='navLink' onClick={() => { redirectTo('/About'); closeNav(); }}>
                                    <i><ContactPageRounded /></i>
                                    <span>{traductionMap.about}</span>
                                </div>
                            </li>
                        </div>
                        <div className='navRight'>
                            <li>
                                {!localStorage.getItem("role") || localStorage.getItem("role") === 'undefined' ?
                                    <div className='navLink' onClick={() => { redirectTo('/Login'); closeNav(); }}>
                                        <i><AccountCircleRounded /></i>
                                        <span>{traductionMap.signIn}</span>
                                    </div>
                                    :
                                    <div className='navLink' onClick={() => {
                                        auth.signOut().then(() => {
                                            localStorage.setItem("role", "undefined");
                                            localStorage.setItem("userPath", "undefined");
                                            window.location.reload();
                                        });
                                    }}>
                                        <i><AccountCircleRounded /></i>
                                        <span>{traductionMap.logOut}</span>
                                    </div>
                                }
                            </li>
                        </div>
                        <li className='navMail'>
                            info@sqip.ch
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
};

export default NavConsumer;
