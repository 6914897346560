import React, { useEffect, useState } from 'react';
import {
    Translate, ArrowBackIosNewRounded, ArrowForwardIosRounded, HomeRounded, AdminPanelSettingsRounded,
    HelpRounded, ContactPageRounded, AccountCircleRounded, Storefront, PointOfSale
} from '@mui/icons-material';
import { auth } from '../firestore';
import { availableLanguages, getCountryList, setLanguage, traductionMap } from '../componants/Traduction';
import { GetBoothNameByFullPath } from '../repostirories/firebaseFunctions';
import { translate } from '../tools/Tools';

const NavAdmin = () => {
    let userRole = localStorage.getItem("role");
    const [isLoading, setIsLoading] = useState(true);
    const [isNavShrink, setIsNavShrink] = useState(window.screen.width < 850 ? true : false);
    const [visibility, setVisibility] = useState('hidden');
    const [boothsVisibility, setBoothsVisibility] = useState('hidden');
    const [boothNames, setBoothNames] = useState({});
    const [currentBooth, setCurrentBooth] = useState({});
    // eslint-disable-next-line
    const paths = localStorage.getItem('userPaths') !== "undefined" ? JSON.parse(localStorage.getItem('userPaths')) : {};

    useEffect(() => {
        async function initBoothNames() {
            const booth = await GetBoothNameByFullPath(localStorage.getItem('userPath'));
            const boothNameList = {};
            for (const key of Object.keys(paths)) {
                boothNameList[key] = await GetBoothNameByFullPath(paths[key]);
            }
            setCurrentBooth(booth);
            setBoothNames(boothNameList);
        }
        initBoothNames();
    }, [paths]);

    const handleNavShrink = () => setIsNavShrink(!isNavShrink);

    auth.onAuthStateChanged(user => {
        if (user) {
            setIsLoading(false);
        }
    });

    const changeLanguage = (l) => {
        const languageKey = Object.keys(availableLanguages).find(key => availableLanguages[key] === l);
        localStorage.setItem('language', languageKey);
        setLanguage(languageKey);
        setVisibility('hidden');
        window.location.reload();
    };

    const changeUserPath = (path) => {
        localStorage.setItem('userPath', path);
        window.location.reload();
    };

    const redirectTo = (path) => {
        window.location.href = `https://sqipprod.web.app${path}`;
    };

    return (
        <div>
            <div className='selectorContainer' style={{ visibility: visibility }}>
                <div style={{ overflow: 'auto', maxHeight: '65%' }}>
                    {getCountryList().map(name =>
                        <div className='selector' onClick={() => changeLanguage(name)}>
                            {name}
                        </div>
                    )}
                </div>
            </div>
            {paths && (userRole === 'Staff' || userRole === 'BoothAdmin') &&
                <div className='selectorContainer' style={{ visibility: boothsVisibility }}>
                    <div style={{ overflow: 'auto', maxHeight: '65%' }}>
                        {Object.keys(paths).map(key =>
                            <div className='selector' onClick={() => changeUserPath(paths[key])}>
                                {translate(boothNames[key])}
                            </div>
                        )}
                    </div>
                </div>
            }
            <nav className={`navAdmin ${isNavShrink ? "navAdminShrink" : "navAdminNoShrink"}`}>
                <div className="navAdminContainer">
                    <div className="shrinkNav" onClick={handleNavShrink}>
                        <i>{isNavShrink ? <ArrowForwardIosRounded /> : <ArrowBackIosNewRounded />}</i>
                    </div>

                    <ul>
                        {isLoading ? <li></li> : <li className="userEmail">{auth.currentUser.email}</li>}
                        <li className='navSeparator'><div></div></li>
                        {paths && (userRole === 'Staff' || userRole === 'BoothAdmin') &&
                            <li>
                                <div className='navLink' onClick={() => setBoothsVisibility('visible')}>
                                    <i><Storefront /></i>
                                    <span>{translate(currentBooth)}</span>
                                </div>
                            </li>
                        }
                        {(userRole === 'Staff' || userRole === 'BoothAdmin') &&
                            <li>
                                <div className='navLink' onClick={() => redirectTo('/fullOrderView')}>
                                    <i><PointOfSale /></i>
                                    <span>{traductionMap.pay}</span>
                                </div>
                            </li>
                        }
                        <li>
                            <div className='navLink' onClick={() => redirectTo('/')}>
                                <i><HomeRounded /></i>
                                <span>{traductionMap.home}</span>
                            </div>
                        </li>
                        
                        {// eslint-disable-next-line
                        userRole === "SuperAdmin" || (userRole === "EventAdmin" && paths) &&
                            <li>
                                <div className='navLink' onClick={() => redirectTo('/AdminConsole')}>
                                    <i><AdminPanelSettingsRounded /></i>
                                    <span>{traductionMap.adminConsole}</span>
                                </div>
                            </li>
                        }
                        {/* Additional navigation items */}
                        <li className='navSeparator'><div></div></li>
                        <li>
                            <div className='navLink' onClick={() => setVisibility('visible')}>
                                <i><Translate /></i>
                                <span>{availableLanguages[localStorage.getItem('language') || navigator.language.substring(0, 2)]}</span>
                            </div>
                        </li>
                        <li>
                            <div className='navLink' onClick={() => redirectTo('/UserGuide')}>
                                <i><HelpRounded /></i>
                                <span>{traductionMap.help}</span>
                            </div>
                        </li>
                        <li>
                            <div className='navLink' onClick={() => redirectTo('/About')}>
                                <i><ContactPageRounded /></i>
                                <span>{traductionMap.about}</span>
                            </div>
                        </li>
                        <li>
                            <div className='navLink' onClick={() => auth.signOut().then(() => {
                                localStorage.setItem("role", "undefined");
                                localStorage.setItem("userPath", "undefined");
                                window.location.reload();
                            })}>
                                <i><AccountCircleRounded /></i>
                                <span>{traductionMap.logOut}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
};

export default NavAdmin;
