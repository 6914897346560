import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import React from 'react'

import Home from '../views/consumerViews/Home';
import About from "../views/consumerViews/About";
import ItemView from "../views/consumerViews/ItemView";
import NavConsumer from "./NavConsumer";
import useInitUser from "../hooks/useInitUser";
import NavAdmin from "./NavAdmin";


// import SmsStressTest from "../tests/SmsStressTest";

const mode = localStorage.getItem('mode')

function Routes() {
  const [userRole] = useInitUser();

  return (
    <div style={{ display: "flex" }}>

      <BrowserRouter>
        {userRole === "undefined" || userRole === null || mode === 'client' ?
          <NavConsumer /> : <NavAdmin />}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
        
          <Route path="/About">
            <About />
          </Route>
          <Route path="/Items">
            <ItemView />
          </Route>
         
          
          
          
          {/* <Route path="/SmsStressTest">
          <SmsStressTest/>
      </Route> */}







        </Switch>

      </BrowserRouter>

    </div>


  );
}
export {Routes as default, mode}
