


function Menu() {
  console.log()
  const itemPath = window.location.href.substring(window.location.href.indexOf('events/'))
  window.location.replace("https://sqipprod.web.app/Items/"+ itemPath)

  localStorage.setItem('sectionNumber', window.location.href.substring(window.location.href.indexOf('/Items') + 7, window.location.href.indexOf('/Items') + 8))
}

export default Menu;
